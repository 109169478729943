import React from "react"
import Layout from "../components/layout"
import Slanted, { NormalHeading } from "../components/layout/slanted"
import SEO from "../components/SEO"
import Pagination from "react-js-pagination"

import { PostList } from "../components/postList"
import { navigate } from "gatsby"

const Posts = ({
  data,
  pageContext: { posts, chunkNum, totalItems, chunkSize },
}) => {
  return (
    <Layout>
      <SEO title="Ta tillbaka kyrkan!"></SEO>
      <Slanted innerClass="bg-white">
        <NormalHeading>Nyheter</NormalHeading>
        <PostList posts={posts} />
        <div className="flex">
          <Pagination
            activePage={chunkNum}
            innerClass={"flex mx-auto list-none"}
            activeLinkClass={"text-gray-400	no-underline	hover:text-gray-400	"}
            activeClass={"text-gray-400	no-underline	hover:text-gray-400"}
            hideFirstLastPages={true}
            itemClass={"mx-2 md:mx-4"}
            itemsCountPerPage={chunkSize}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={num => {
              if (num != chunkNum) {
                navigate(`/senaste/${num}`)
              }
            }}
          />
        </div>
      </Slanted>
    </Layout>
  )
}

export default Posts
